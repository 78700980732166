import {
  httpClientGet,
  authHttpClientPost,
  authHttpClientGet,
} from "@/services/api/axios";
// export default {
//     async getRobotDetail() {
//         try {
//             const { data } = await axiosInstance.get(`/robot/detail?id=MC6x_future_BTCUSDT`)
//             console.log(data);
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }

export async function getRobotList() {
  try {
    return await httpClientGet(`/robot/list`);
  } catch (error) {
    return [];
  }
}

export async function getRobotDetail(crypto_id) {
  try {
    // const { data } = await axiosInstance.get(`/robot/detail?id=MC6x_future_BTCUSDT`)
    return await httpClientGet(`/robot/detail?id=${crypto_id}`);
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailNumerical(crypto_id) {
  try {
    return await httpClientGet(`/robot/detail/numberical?id=${crypto_id}`);
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailAnalytic(crypto_id) {
  try {
    return await httpClientGet(`/robot/detail/analytical?id=${crypto_id}`);
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailAnalyticEquity(crypto_id) {
  try {
    return await httpClientGet(
      `/robot/detail/analytical/equity_chart?id=${crypto_id}`
    );
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailHistogramOfReturn(crypto_id) {
  try {
    return await httpClientGet(
      `/robot/detail/analytical/histogram_of_return_chart?id=${crypto_id}`
    );
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailMonthlyReturn(crypto_id) {
  try {
    return await httpClientGet(
      `/robot/detail/analytical/monthly_return_chart?id=${crypto_id}`
    );
  } catch (error) {
    return {};
  }
}

export async function getRobotDetailWinLoss(crypto_id) {
  try {
    return await httpClientGet(
      `/robot/detail/analytical/win_loss_chart?id=${crypto_id}`
    );
  } catch (error) {
    return {};
  }
}
// /robot/detail/analytical/equity_chart?id=test_MC6x_future_BTCUSDT

// auth
export async function getInappEntitlement(crypto_id) {
  try {
    let res_data = await authHttpClientPost("/inapp/entitlement", {
      id: crypto_id,
    });
    if (res_data["code"] === 200) {
      return res_data["data"];
    } else {
      return { isActive: false };
    }
  } catch (error) {
    return { isActive: false };
  }
}

export async function getInappTrial(crypto_id) {
  try {
    let res_data = await authHttpClientPost("/inapp/checktrial", {
      robotId: crypto_id,
    });
    if (res_data["code"] === 200) {
      return res_data;
    } else {
      return { status: 400, trial_offer: false };
    }
  } catch (error) {
    return { status: 500, trial_offer: false };
  }
}

export async function checkBinanceKey(apiKey, secretKey) {
  try {
    let res_data = await authHttpClientPost("/rent/verifyapisecretkey", {
      apiKey,
      secretKey,
      region: "crypto",
      market: "future",
    });
    if (res_data["code"] === 200) {
      return res_data["data"];
    } else {
      return { isActive: false };
    }
  } catch (error) {
    return { isActive: false };
  }
}

export async function getProductList(robotCode) {
  try {
    return await httpClientGet(
      `/webpurchase/productlist?robotCode=${robotCode}`
    );
  } catch (error) {
    return [];
  }
}

export async function getLeverageDetail(robotCode) {
  try {
    return await httpClientGet(`/robot/leveragevars?id=${robotCode}`);
  } catch (error) {
    return error;
  }
}

//
export async function getClientIp() {
  try {
    return await httpClientGet("/tool/ipaddress");
  } catch (error) {
    return error;
  }
}

export async function createPrePurchase(payload) {
  try {
    return await authHttpClientPost(
      `/webpurchase/chillpay/prepurchase`,
      payload
    );
  } catch (error) {
    return { code: error.code, message: error["data"]["message"]["message"] };
  }
}

export async function getPaymentDetail(orderId) {
  try {
    return await authHttpClientGet(
      `/webpurchase/chillpay/paymentdetail?orderId=${orderId}`
    );
  } catch (error) {
    return [];
  }
}

// free trial
export async function getInAppFreeTrial(apiKey, secretKey, robotId, leverage) {
  try {
    let res_data = await authHttpClientPost("/inapp/trailpurchase", {
      apiKey,
      secretKey,
      robotId,
      leverage,
      platform: "web",
    });
    return res_data;
  } catch (error) {
    return error;
  }
}
